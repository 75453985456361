import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/Header.css';
import ExitOffice from './ExitOffice';
import About from './About';
import LanguageDropdown from './LanguageDropdown';
import { VOfficeContext } from '../context';
import { ReactComponent as Arrow } from '../assets/arrow.svg';
import GA from '../googleAnalytics';

const Header = (props) => {
  const {
    setCurrentVideo,
    currentVideo,
    isAboutVisible,
    setIsAboutVisible,
    currentSection,
    setCurrentSection,
    answers,
    setAnswers,
    videoRef,
  } = useContext(VOfficeContext);

  const handleBackButton = () => {
    if (currentVideo === 'about') {
      setCurrentVideo('survey');
      setIsAboutVisible(true);
    } else {
      const newAnswers = Array.from(answers);
      const lastAnsr = newAnswers.pop();

      setCurrentSection(lastAnsr.sectionId);
      setAnswers(newAnswers);
      videoRef.current.play();

      GA.event({ action: 'Returned to previous question' });
    }
  };

  const { t } = useTranslation();

  return (
    <div className="header">
      <div className="header-left-buttons">
        {
          isAboutVisible && currentSection === 'section1'
            ? null
            : (
              <button
                className="header-button back-button"
                onClick={handleBackButton}
              >
                <Arrow />
                <p className="back-button-text">
                  {t('backButton')}
                </p>
              </button>
            )
        }
        { isAboutVisible && <About /> }
      </div>
      <div className="header-right-buttons">
        <LanguageDropdown />
        <ExitOffice />
      </div>
    </div>
  );
};

export default Header;
