import React, { useState, useEffect, useRef } from 'react';
import '../styles/App.css';
import Video from './Video';
import Header from './Header';
import { VOfficeContext } from '../context';
import { useTranslation } from 'react-i18next';
import { VIDEOS } from '../videos';
import { VIDEO_SECTIONS } from '../videoSections';
import GA from '../googleAnalytics';

function App() {
  // context stuff
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [language, setLanguage] = useState('en-US');
  const [currentSection, setCurrentSection] = useState('section1');
  const [currentVideo, setCurrentVideo] = useState('survey');
  const [isMobile, setIsMobile] = useState(true);
  const [answers, setAnswers] = useState([]);
  const [isAboutVisible, setIsAboutVisible] = useState(true);

  const { i18n } = useTranslation();

  const playerRef = useRef(null);

  useEffect(() => {
    const pathname = window.location.pathname.substr(1);

    if (pathname.length === 0) {
      const newPathname = i18n.language === 'ar' ? 'ar' : 'en';
      window.location.pathname = `/${newPathname}`;
    } else {
      switch (pathname) {
        case 'ar': {
          i18n.changeLanguage('ar');
          setLanguage('ar');
          break;
        }
        case 'en': {
          i18n.changeLanguage('en');
          setLanguage('en');
          break;
        }
        default: {
          window.location.pathname = '/en';
        }
      }
    }
  }, []);

  useEffect(() => { // Cache validation hook
    const semverGreaterThan = (versionA, versionB) => {
      const versionsA = versionA.split(/\./g);

      const versionsB = versionB.split(/\./g);
      while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());

        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
      }
      return false;
    };

    const refreshCacheAndReload = () => {
      // console.log('Clearing cache and hard reloading...');
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
      }
      // delete browser cache and hard reload
      window.location.reload(true);
    };

    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = process.env.REACT_APP_VERSION;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

        if (shouldForceRefresh) {
          // console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          refreshCacheAndReload();
        } else {
          // console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
        }
      })
  }, []);

  useEffect(() => {
    GA.set({ language: i18n.language });
  }, []);

  useEffect(() => {
    setIsOverlayVisible(false);
    if (i18n.language.includes('en')) {
      document.title = 'Bluemina Virtual Office';
    } else {
      document.title = 'المكتب الإفتراضي من بلومينا';
    }
  }, [i18n.language]);

  useEffect(() => {
    const { innerHeight, innerWidth } = window;

    if (innerWidth > innerHeight) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, []);

  let src;
  const lang = i18n.language.includes('en') ? 'en' : i18n.language;
  if (isMobile) {
    src = VIDEOS[currentVideo].mobile[lang];
  } else {
    src = VIDEOS[currentVideo].desktop[lang];
  }

  return (
    <div className="app">
      <VOfficeContext.Provider
        value={{
          isOverlayVisible,
          setIsOverlayVisible,
          language,
          setLanguage,
          currentSection,
          setCurrentSection,
          currentVideo,
          setCurrentVideo,
          isMobile,
          setIsMobile,
          answers,
          setAnswers,
          isAboutVisible,
          setIsAboutVisible,
          videoRef: playerRef,
          videoSections: VIDEO_SECTIONS,
        }}
      >
        <Header />
        <Video
          playerRef={playerRef}
          src={src || VIDEOS.survey.desktop.en}
          autoplay={false}
          controls={false}
        />
      </VOfficeContext.Provider>
    </div>
  );
}

export default App;
