const VIDEOS = {
  survey: {
    mobile: {
      en: 'https://client-videos.buttercloud.com/en/mobile/all/bluemina-en-all-mobile.m3u8#t=0.001',
      ar: 'https://client-videos.buttercloud.com/ar-v2/mobile/all/bluemina-ar-v2-all-mobile.m3u8#t=0.001',
    },
    desktop: {
      en: 'https://client-videos.buttercloud.com/en/all/bluemina-en-all.m3u8',
      ar: 'https://client-videos.buttercloud.com/ar-v2/all/bluemina-ar-v2-all.m3u8',
    },
  },
  about: {
    mobile: {
      en: 'https://client-videos.buttercloud.com/en/mobile/about/bluemina-en-about-mobile.m3u8#t=0.001',
      ar: 'https://client-videos.buttercloud.com/ar-v2/mobile/about/bluemina-ar-v2-about-mobile.m3u8#t=0.001'
    },
    desktop: {
      en: 'https://client-videos.buttercloud.com/en/about/bluemina-en-about.m3u8',
      ar: 'https://client-videos.buttercloud.com/ar-v2/about/bluemina-ar-v2-about.m3u8',
    },
  },
};

export {
  VIDEOS,
};
