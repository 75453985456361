import React, {
  useEffect,
  useContext,
  useState,
} from 'react';
import ReactHlsPlayer from 'react-hls-player';
import '../styles/Video.css';
import { VOfficeContext } from '../context';
import { useTranslation } from 'react-i18next';
import VideoOverlay from './VideoOverlay';
import GA from '../googleAnalytics';

const Video = (props) => {
  const {
    src,
    autoplay,
    controls,
    playerRef,
  } = props;

  const {
    setIsOverlayVisible,
    videoSections,
    currentSection,
    currentVideo,
    isMobile,
  } = useContext(VOfficeContext);

  const [showPlayButton, setShowPlayButton] = useState(true);

  const device = isMobile ? 'mobile' : 'desktop';

  const sectionData = videoSections[currentSection];

  const { t, i18n } = useTranslation();

  const currentLang = i18n.language.includes('en') ? 'en' : 'ar';

  const playVideo = () => playerRef.current?.play();
  const pauseVideo = () => playerRef.current?.pause();
  // const unMuteVideo = () => playerRef.current.muted = false;
  // const toggleControls = () => playerRef.current.controls = !playerRef.current.controls;

  const showOverlay = () => setIsOverlayVisible(true);

  const handlePlayButton = () => {
    GA.event({ action: 'Started Virtual Office' });

    playVideo();
    setShowPlayButton(false);
  };

  const goToCurrentSection = () => {
    playerRef.current.currentTime = sectionData.startTime[device][currentLang] || 0;
  };

  useEffect(() => {
    const ref = playerRef.current;

    const onTimeupdate = () => {
      if (currentVideo === 'about') return;

      if (sectionData.showOverlayAt) {
        if (playerRef.current?.currentTime >= sectionData.showOverlayAt[device][currentLang]) {
          showOverlay();
        }
      }

      if (playerRef.current.currentTime >= sectionData.finishTime[device][currentLang]) {
        pauseVideo();
      }
    };

    ref.addEventListener('timeupdate', onTimeupdate);
    return () => {
      ref.removeEventListener('timeupdate', onTimeupdate);
    }
  }, [currentSection, currentLang, currentVideo, device, playerRef, sectionData]);

  useEffect(() => {
    if (currentVideo === 'about') {
      handlePlayButton();
      return;
    }

    // playerRef.current.currentTime = 0;
    goToCurrentSection();
  }, [currentVideo]);

  useEffect(() => {
    if (currentVideo === 'about') return;

    goToCurrentSection();
    setIsOverlayVisible(false);

    if (currentSection === 'section1') return;

    playVideo();
  }, [currentSection]);

  useEffect(() => {
    if (currentVideo === 'about') {
      playVideo();
      return;
    }

    if (currentSection === 'section1') {
      setShowPlayButton(true);
      return;
    }

    const ref = playerRef.current;

    const onLoadedData = () => {
      goToCurrentSection();
      playVideo();

      ref.removeEventListener('loadeddata', onLoadedData);
    };

    ref.addEventListener('loadeddata', onLoadedData);
  }, [src]);

  const type = currentVideo === 'about' ? 'about' : 'survey';
  const poster = `/poster-${type}-${device}-${currentLang}.png`;

  return (
    <div className="video-container">
      {
        showPlayButton && (
          <div className="play-button-container">
            <button
              className="main-rounded-cta"
              onClick={handlePlayButton}
            >
              {t('startVideoButton')}
            </button>
          </div>
        )
      }
      <VideoOverlay />
      <ReactHlsPlayer
        type="application/x-mpegURL"
        playerRef={playerRef}
        src={src}
        autoPlay={autoplay}
        controls={controls}
        width="100%"
        height="auto"
        poster={poster}
        playsInline
        webkit-playsinline="true"
      />
    </div>
  );
};

export default Video;
