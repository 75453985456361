import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../styles/ExitOffice.css';
import Modal from './Modal';
import { ReactComponent as WhatsappIcon } from '../assets/whatsapp.svg';
import Close from '../assets/Close';
import GA from '../googleAnalytics';

const ExitOffice = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const { t } = useTranslation();

  const handleOnChange = (event) => setPhoneNumber(event.target.value);

  const handleSubmit = () => console.log('TODO');

  const handleClose = () => setIsModalVisible(false);

  const triggerCloseEvent = () => {
    GA.event({ action: 'Closed virtual office' });
  };

  return (
    <>
      <Modal show={isModalVisible}>
        <div className="exit-office-block">
          <div className="request-a-call-header-block">
            <div className="request-a-call-header-inner">
              <div className="request-a-call-icons">
                <WhatsappIcon width="50px" height="50px" />
                <button
                  className="close-modal-button"
                  onClick={handleClose}
                >
                  <Close />
                </button>
              </div>
              <p className="request-a-call-header-text">
                {t('exitOffice.modal.header')}
              </p>
            </div>
          </div>
          <div className="request-a-call-body">
            <p className="request-a-call-body-text">
              {t('exitOffice.modal.body')}
            </p>
            <div className="phone-number-input-block">
              <input
                className="phone-number-input"
                type="tel"
                placeholder={t('exitOffice.modal.inputPlaceholder')}
                value={phoneNumber}
                onChange={handleOnChange}
              />
              <button
                className="send-button"
                onClick={handleSubmit}
              >
                {t('exitOffice.modal.submitButton')}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <a
        href="https://www.bluemina.com/#apply"
        target="_parent"
        onClick={triggerCloseEvent}
      >
        <button className="exit-button">
          <Close color="white" />
        </button>
      </a>
    </>
  );
};

export default ExitOffice;
