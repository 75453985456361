import React, { useContext } from 'react';
import '../styles/VideoOverlay.css';
import { VOfficeContext } from '../context';
import { useTranslation } from 'react-i18next';
import Calendly from './Calendly';
// import { ReactComponent as LinkIcon } from '../assets/link.svg';
import GA from '../googleAnalytics';

const TYPES = {
  question: 'question',
  calendly: 'calendly',
  exit: 'exit',
};

const QuestionType = ({ sectionData, handleAnswer, sectionId }) => {
  const { answer1, answer2 } = sectionData;

  const { t, i18n } = useTranslation();

  const currentLang = i18n.language.includes('en') ? 'en' : 'ar';

  const Icon1 = answer1?.icon;
  const Icon2 = answer2?.icon;

  return (
    <>
      <p className="question">
        {sectionData?.text?.[currentLang]}
      </p>
      <div className="answers-container">
        <button
          className={`answer-button ${answer1?.cssClass}`}
          onClick={() => handleAnswer(sectionId, 'answer1')}
        >
          { Icon1 && <Icon1 width="50" height="40" /> }
          <p className="answer-button-text">
            {answer1?.text?.[currentLang]}
          </p>
        </button>
        <p className="answers-separator d-none">
          {t('overlay.answerSeparator')}
        </p>
        <button
          className={`answer-button ${answer2?.cssClass}`}
          onClick={() => handleAnswer(sectionId, 'answer2')}
        >
          { Icon2 && <Icon2 width="50" height="40" /> }
          <p className="answer-button-text">
            {answer2?.text?.[currentLang]}
          </p>
        </button>
      </div>
    </>
  );
};

const ExitType = ({ sectionData }) => {
  const { t, i18n } = useTranslation();

  const currentLink = i18n.language.includes('en') ? 'https://www.bluemina.com/programs-summary/' : 'https://www.bluemina.com/ar/%d9%85%d8%b9%d9%84%d9%88%d9%85%d8%a7%d8%aa-%d8%a8%d8%b1%d8%a7%d9%85%d8%ac-%d8%a7%d9%84%d8%af%d9%88%d9%84/';

  return (
    <div className="video-container">
      {
        <div className="play-button-container">
          <a
            href={currentLink}
            className="main-rounded-cta"
          >
            {t('exitButton')}
          </a>
        </div>
      }
    </div>
    // /* No exit block for now, but let's maintain support */
    // <div className="exit-block">
    //   <p className="exit-block-text">
    //     {t('overlay.exitBlock.header')}
    //   </p>
    //   <a
    //     href="https://www.bluemina.com/programs-summary/"
    //     className="bluemina-anchor"
    //     target="_parent"
    //   >
    //     <button className="bluemina-url-button">
    //       <LinkIcon />
    //       <p className="bluemina-url-button-text">
    //         {t('overlay.exitBlock.urlLabel')}
    //       </p>
    //     </button>
    //   </a>
    // </div>
  );
};

const SurveyQuestionsOverlay = () => {
  const {
    videoSections,
    isOverlayVisible,
    setIsOverlayVisible,
    currentSection,
    setCurrentSection,
    answers,
    setAnswers,
    currentVideo,
  } = useContext(VOfficeContext);

  const handleAnswer = (sectionId, answerId) => {
    setIsOverlayVisible(false);
    setCurrentSection(videoSections[currentSection][answerId].next);

    const newAnswers = Array.from(answers);
    newAnswers.push({
      sectionId,
      answerId,
    });

    setAnswers(newAnswers);

    const secData = videoSections[sectionId];
    GA.set({ [secData.text.en]: secData[answerId].text.en });
    GA.event({ action: 'Answered a question' });
  };

  if (!isOverlayVisible || currentVideo === 'about') return null;

  const sectionData = videoSections[currentSection];

  return (
    <div className="overlay">
      {
        sectionData.type === TYPES.question && (
          <QuestionType
            sectionId={currentSection}
            sectionData={sectionData}
            handleAnswer={handleAnswer}
          />
        )
      }
      { sectionData.type === TYPES.calendly && <Calendly /> }
      { sectionData.type === TYPES.exit && (
          <ExitType
            sectionData={sectionData}
          />
        )
      }
    </div>
  );
};

export default SurveyQuestionsOverlay;
