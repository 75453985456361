import React from 'react';

function Close(props) {
  const { color } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.55}
      height={17.679}
      viewBox="0 0 21.55 19.679"
      {...props}
    >
      <g
        data-name="Group 30"
        fill="none"
        stroke={color || '#000'}
        strokeWidth={2}
      >
        <path data-name="Path 979" d="M.667.743l20.212 18.193" />
        <path data-name="Path 980" d="M20.881.743L.669 18.936" />
      </g>
    </svg>
  );
}

export default Close;
