import React from 'react';

const VOfficeContext = React.createContext({
  isOverlayVisible: false,
  setIsOverlayVisible: () => {},
  language: 'en-US',
  setLanguage: () => {},
  videoSections: {},
  currentSection: '',
  setCurrentSection: () => {},
  currentVideo: 'survey',
  setCurrentVideo: () => {},
  isMobile: true,
  setIsMobile: () => {},
  answers: [],
  setAnswers: () => {},
  isAboutVisible: true,
  setIsAboutVisible: () => {},
});

export {
  VOfficeContext,
};
