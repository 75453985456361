import ReactGA from 'react-ga';

const trackingID = process.env.NODE_ENV === 'production' ? 'UA-92259906-2' : 'test';

const isDev = process.env.NODE_ENV === 'development';

const initialize = () => {
  ReactGA.initialize(trackingID, {
    debug: isDev,
  });
};

const set = (data) => {
  ReactGA.set({ ...data });
  ReactGA.pageview('virtual-office');
};

const event = (data) => {
  ReactGA.event({
    category: 'Virtual Office',
    ...data,
  });
};

const methods = {
  initialize,
  set,
  event,
};

export default methods;
