import React, { useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import '../styles/LanguageDropdown.css';
import { VOfficeContext } from '../context';
import { ReactComponent as Arrow } from '../assets/arrow.svg';
import GA from '../googleAnalytics';

const languages = [
  { value: 'ar', label: 'AR' },
  { value: 'en-US', label: 'EN' },
];

const LanguageDropdown = (props) => {
  const { setLanguage } = useContext(VOfficeContext);

  const { t, i18n } = useTranslation();

  const referenceRef = useRef(null);
  const popperRef = useRef(null);

  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => setMenuVisible(!menuVisible);

  const { attributes } = usePopper(
    referenceRef.current,
    popperRef.current,
    {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          enabled: true,
          options: {
            offset: [0, 0],
          },
        },
      ],
    },
  );

  const changeLanguage = (selectedLng) => {
    setMenuVisible(false);
    if (i18n.language === selectedLng) return;

    i18n.changeLanguage(selectedLng);
    setLanguage(selectedLng);

    GA.set({ language: selectedLng });
    GA.event({ action: 'Changed Language' });

    const pathname = selectedLng === 'en-US' ? 'en' : 'ar';
    window.history.pushState(pathname, '', `/${pathname}`);
  };

  return (
    <div ref={referenceRef}>
      <button
        className="header-button lang-dropdown"
        onClick={toggleMenu}
      >
        <p className="lang-dropdown-label">
          {t('languageDropdown.label')}
        </p>
        <Arrow
          // IM - using transform inside style instead of
          // its own prop for safari to work.
          style={{ transform: "rotate(-90deg)" }}
        />
      </button>
      {
        menuVisible && (
          <div
            ref={popperRef}
            className="popper-container"
            // Next line is disabled in eslint because its coming from popper
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...attributes.popper}
          >
            <div className="dropdown-items-container">
              {
                languages.map((lang) => (
                  <button
                    key={lang.value}
                    className="dropdown-item"
                    onClick={() => changeLanguage(lang.value)}
                  >
                    {lang.label}
                  </button>
                ))
              }
            </div>
          </div>
        )
      }
    </div>
  );
};

export default LanguageDropdown;
