import React, { useContext } from 'react';
import '../styles/About.css';
import { VOfficeContext } from '../context';
import { useTranslation } from 'react-i18next';
import GA from '../googleAnalytics';

const About = (props) => {
  const {
    setCurrentVideo,
    setIsOverlayVisible,
    setIsAboutVisible,
  } = useContext(VOfficeContext);

  const { t } = useTranslation();

  const handleOnClick = () => {
    setIsOverlayVisible(false);
    setIsAboutVisible(false);
    setCurrentVideo('about');

    GA.event({ action: 'Clicked About' });
  };

  return (
    <button
      className="header-button about-button"
      onClick={handleOnClick}
    >
      {t('about.button')}
    </button>
  );
};

export default About;
