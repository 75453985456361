import { ReactComponent as Passport } from './assets/passport.svg';
import { ReactComponent as Residency } from './assets/residency.svg';

const VIDEO_SECTIONS = {
  section1: {
    type: 'question', // [question, calendly, exit]
    text: {
      en: 'What brings you here today?',
      ar: 'لماذا أنت هنا اليوم؟',
    },
    answer1: {
      text: {
        en: 'Second Passport',
        ar: 'جواز السفر الثاني',
      },
      next: 'section3',
      icon: Passport,
    },
    answer2: {
      text: {
        en: 'Permanent Residency',
        ar: 'إقامة دائمة',
      },
      next: 'section2',
      icon: Residency,
    },
    startTime: {
      mobile: {
        en: 0, // in seconds
        ar: 0,
      },
      desktop: {
        en: 0,
        ar: 0,
      },
    },
    showOverlayAt: {
      mobile: {
        en: 20.23,
        ar: 10.37,
      },
      desktop: {
        en: 20.23,
        ar: 10.44,
      },
    },
    finishTime: {
      mobile: {
        en: 49,
        ar: 23.13,
      },
      desktop: {
        en: 48,
        ar: 23.05,
      },
    },
  },
  section2: {
    type: 'question',
    text: {
      en: 'Is this amount within your budget?',
      ar: 'هل هذا المبلغ ضمن الإمكانية؟',
    },
    answer1: {
      text: {
        en: 'yes',
        ar: 'نعم',
      },
      next: 'section4',
      cssClass: 'yes-btn',
    },
    answer2: {
      text: {
        en: 'No',
        ar: 'لا',
      },
      next: 'section5',
      cssClass: 'no-btn',
    },
    startTime: {
      mobile: {
        en: 53.97,
        ar: 25.08,
      },
      desktop: {
        en: 53.97,
        ar: 25.06,
      },
    },
    showOverlayAt: {
      mobile: {
        en: 67,
        ar: 36.85,
      },
      desktop: {
        en: 67,
        ar: 36.85,
      },
    },
    finishTime: {
      mobile: {
        en: 69,
        ar: 41.12,
      },
      desktop: {
        en: 69,
        ar: 41.12,
      },
    },
  },
  section3: {
    type: 'question',
    text: {
      en: 'Is this amount within your budget?',
      ar: 'هل هذا المبلغ ضمن الإمكانية؟',
    },
    answer1: {
      text: {
        en: 'yes',
        ar: 'نعم',
      },
      next: 'section4',
      cssClass: 'yes-btn',
    },
    answer2: {
      text: {
        en: 'No',
        ar: 'لا',
      },
      next: 'section5',
      cssClass: 'no-btn',
    },
    startTime: {
      mobile: {
        en: 73.5,
        ar: 58.86,
      },
      desktop: {
        en: 73.5,
        ar: 58.86,
      },
    },
    showOverlayAt: {
      mobile: {
        en: 84,
        ar: 70.37,
      },
      desktop: {
        en: 84,
        ar: 70.37,
      },
    },
    finishTime: {
      mobile: {
        en: 103,
        ar: 73.00,
      },
      desktop: {
        en: 103,
        ar: 73.00,
      },
    },
  },
  section4: {
    type: 'calendly',
    startTime: {
      mobile: {
        en: 109.5,
        ar: 74.3,
      },
      desktop: {
        en: 109.5,
        ar: 74.3,
      },
    },
    showOverlayAt: {
      mobile: {
        en: 114,
        ar: 76.55,
      },
      desktop: {
        en: 114,
        ar: 76.55,
      },
    },
    finishTime: {
      mobile: {
        en: 114.5,
        ar: 78.9,
      },
      desktop: {
        en: 114.5,
        ar: 79,
      },
    },
  },
  section5: {
    type: 'exit',
    startTime: {
      mobile: {
        en: 133.8,
        ar: 86.06,
      },
      desktop: {
        en: 133.8,
        ar: 86.06,
      },
    },
    showOverlayAt: {
      mobile: {
        en: 143,
        ar: 92,
      },
      desktop: {
        en: 143,
        ar: 92,
      },
    },
    finishTime: {
      mobile: {
        en: 152,
        ar: 96,
      },
      desktop: {
        en: 152,
        ar: 96,
      },
    },
  },
};

export {
  VIDEO_SECTIONS,
};
