import React, { useContext } from 'react';
import { InlineWidget } from 'react-calendly';
import '../styles/Calendly.css';
import { VOfficeContext } from '../context';
import { useTranslation } from 'react-i18next';

const URL = "https://calendly.com/bluemina/experiment1et?hide_landing_page_details=1&hide_gdpr_banner=1";

const STYLES = {
  minWidth: '320px',
  // height: '750px',
  height: '100%',
};

const Calendly = () => {
  const { answers } = useContext(VOfficeContext);

  const { i18n } = useTranslation();

  const customAns = {};

  answers.forEach((ans) => {
    const section1Ans = answers.find((obj) => obj.sectionId === 'section1');

    customAns.a2 = section1Ans.answerId === 'answer1' ? 1 : 2;
    customAns.a3 = i18n.language === 'ar' ? 2 : 1;
  });

  return (
    <div className="calendly-container">
      <InlineWidget
        url={URL}
        styles={STYLES}
        prefill={{
          customAnswers: customAns,
        }}
      />
    </div>
  );
};

export default Calendly;
